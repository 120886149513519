<template>
  <div class="container">
    <div class="_Tab">
      <span
        v-for="(item, index) in ['联系我们', '商务合作', '申请记录']"
        :key="index"
        :class="{ activeColor: index == activatIndex }"
        @click="clickTabItem(index)"
        >{{ item }}</span
      >
    </div>
    <div class="_SelectHeader select-box" v-if="activatIndex != 2">
      <div>
        <span style="width: 100px">开始时间：</span>
        <!-- <el-date-picker
          v-model="value"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeTime">
        </el-date-picker> -->
        <el-date-picker
          style="width: 160px"
          v-model="startTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="开始日期"
        >
        </el-date-picker>
      </div>
      <div style="margin-left: 14px">
        <span style="width: 100px">结束时间：</span>
        <el-date-picker
          style="width: 160px"
          v-model="stopTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="_Select" v-show="activatIndex == 0">
        <span style="width: 100px">反馈类型：</span>
        <el-select v-model="type" clearable placeholder="请选择">
          <el-option
            v-for="item in typeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <el-button icon="el-icon-search" @click="handleSearch"></el-button>
      <el-button type="primary" v-show="activatIndex == 1"
        >导出用户名单</el-button
      >
      <el-button
        type="primary"
        class="report"
        @click="reportBtn"
        v-show="activatIndex == 0"
        >发出报告</el-button
      >
    </div>
    <div class="_TableBox" v-if="activatIndex != 2">
      <el-table
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        :stripe="true"
        v-if="activatIndex == 0"
      >
        <el-table-column type="selection" width="50" />
        <el-table-column label="用户姓名" prop="name" />
        <el-table-column label="用户电话" prop="phoneNum" />
        <el-table-column label="信息来源" prop="tenantId">
          <template slot-scope="scope">
            <img
              v-if="scope.row.tenantId == 1"
              src="../../assets/images/pc.png"
              alt=""
            />
            <img v-else src="../../assets/images/mobile.png" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="反馈类型" prop="type" />
        <el-table-column label="状态" prop="tenantId">
          <template slot-scope="scope">
            <span class="untreated" v-if="scope.row.tenantId == 1">未处理</span>
            <span v-else class="processed">已处理</span>
          </template>
        </el-table-column>
        <el-table-column label="问题描述" width="400" prop="remark" />
        <!-- <el-table-column label="添加附件">
          <template slot-scope="scope">
            <span style="color:#2f98ff" @click="handleSee(scope.row.lstImage)">{{scope.row.lstImage.length}}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="处理记录" prop="taskStatus" />
        <el-table-column label="处理方式" prop="taskStatus" /> -->
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div class="spanBox">
              <span v-if="scope.row.taskStatus == '已完成'">已处理</span>
              <span v-else>发出报告</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        :data="userTable"
        :height="elementHeight"
        tooltip-effect="dark"
        :stripe="true"
        v-else
      >
        <el-table-column type="selection" width="50" />
        <el-table-column label="用户姓名"></el-table-column>
        <el-table-column label="用户电话"></el-table-column>
        <el-table-column label="信息来源"></el-table-column>
        <el-table-column label="公司名"></el-table-column>
        <el-table-column label="公司地址"></el-table-column>
        <el-table-column label="用户留言"></el-table-column>
      </el-table>
      <el-pagination
        class="_Pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <div v-if="activatIndex == 2">
      <span class="_SelectHeader select-box">
        <span>状态：</span>
        <el-select
          style="width: 120px"
          v-model="statues"
          placeholder="请选择"
          @change="getApplyList"
        >
          <el-option
            v-for="item in ['全部', '已通过', '未通过']"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </span>
      <div class="_TableBox">
        <el-table
          :data="applyList.slice(start3, end3)"
          :height="elementHeight"
          :stripe="true"
        >
          <el-table-column type="index" width="100" />
          <el-table-column label="姓名" prop="userName"></el-table-column>
          <el-table-column label="电话" prop="userPhone"></el-table-column>
          <el-table-column
            label="企业名称"
            prop="userCompany"
          ></el-table-column>
          <el-table-column label="职位" prop="userPhone"></el-table-column>
          <el-table-column label="邮箱" prop="userEmail"></el-table-column>
          <el-table-column label="申请时间" prop="saveTime">
            <template slot-scope="scope">
              <span>{{ scope.row.saveTime.split("T")[0] }}</span>
            </template></el-table-column
          >
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <div class="spanBox">
                <span
                  @click="putApplyItem(scope.row.id)"
                  v-if="scope.row.statues == 0"
                  >通过</span
                >
                <span
                  v-if="scope.row.statues == 1"
                  style="background: #b5cafb; cursor: default"
                  >已通过</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="_Pagination"
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page.sync="currentPage3"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize3"
          layout="total, sizes, prev, pager, next, jumper"
          :total="applyList.length"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="发出报告" :visible.sync="reportShow" width="483px">
      <el-form :model="form" label-width="auto">
        <el-form-item label="处理方式">
          <el-select
            v-model="form.company"
            clearable
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理记录">
          <el-input
            type="textarea"
            v-model="form.record"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reportShow = false">取 消</el-button>
        <el-button type="primary" @click="reportShow = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看附件" :visible.sync="imgShow" width="483px">
      <div class="imgBox">
        <div v-for="item in imgList" :key="item">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="imgList"
          >
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL, get, put } from "../../api/http";
export default {
  data() {
    return {
      activatIndex: 0,
      type: "功能故障",
      typeOption: [
        {
          value: "功能故障",
          label: "功能故障",
        },
        {
          value: "意见&建议",
          label: "意见&建议",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      elementHeight: 0,
      tableData: [],
      userTable: [],
      currentPage: 1,
      pageSize: 10,
      reportShow: false,
      form: {},
      imgList: [],
      imgShow: false,
      baseUrl: baseURL[process.env.NODE_ENV + ""],
      startTime: "",
      stopTime: "",
      count: 0,
      applyList: [],
      start3: 0,
      end3: 10,
      currentPage3: 0,
      curSize3: 10,
      statues: "全部",
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    clickTabItem(i) {
      this.activatIndex = i;
      this.getElementHeight();
      if (i == 2) this.getApplyList();
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 40 + 70 + 56); //70是div底部距离底部的高度
      });
    },
    // 获取列表数据
    getList() {
      let queryParams = {
        startTime: this.startTime,
        stopTime: this.stopTime,
        typeName: this.type,
      };
      get("/api/Contact/GetByTimeOrType", queryParams).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.count = res.count;
        }
      });
    },
    // changeTime(e){
    //   if(!e){
    //     this.startTime = '';
    //     this.stopTime = '';
    //     return;
    //   }
    //   this.startTime = e[0];
    //   this.stopTime = e[1];
    // },
    // 搜索
    handleSearch() {
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
    },
    handleCurrentChange(e) {
      this.currentPage = e;
    },
    handleSizeChange3(e) {
      this.curSize3 = e;
      this.start3 = (this.currentPage3 - 1) * this.curSize3;
      this.end3 = this.start3 + this.curSize3;
    },
    handleCurrentChange3(e) {
      this.start3 = (e - 1) * this.curSize3;
      this.end3 = this.start3 + this.curSize3;
    },
    // 查看附件
    handleSee(data) {
      this.imgList = [];
      data.forEach((item) => {
        this.imgList.push(
          this.baseUrl + "/uploadContacts/" + item.imageAddress
        );
      });
      this.imgShow = true;
    },
    reportBtn() {
      this.reportShow = true;
    },
    // 用户申请记录
    getApplyList() {
      get("/api/TryOutUserList/GetList").then((res) => {
        if (res.code == 200) {
          if (this.statues == "全部") {
            this.applyList = res.data;
          }
          if (this.statues == "已通过") {
            this.applyList = res.data.filter((i) => {
              return i.statues == 1;
            });
          }
          if (this.statues == "未通过") {
            this.applyList = res.data.filter((i) => {
              return i.statues == 0;
            });
          }
        }
      });
    },
    putApplyItem(id) {
      put("/api/TryOutUserList/PutOver?tryputId=" + id)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getApplyList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error("操作失败，请稍后再试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  .report {
    position: absolute;
    right: 15px;
  }
}
.untreated {
  display: inline-block;
  width: 73px;
  height: 30px;
  background: #dfeafe;
  text-align: center;
  line-height: 30px;
}
.processed {
  display: inline-block;
  width: 73px;
  height: 30px;
  background: #bef4cd;
  text-align: center;
  line-height: 30px;
}
._Tab {
  color: #a09f9f;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

._Tab .activeColor {
  border-bottom: 2px solid #2f98ff;
  color: black;
}

._Tab span {
  padding: 10px 0;
}

.spanBox {
  span {
    display: inline-block;
    text-align: center;
    line-height: 30px;
    width: 94px;
    height: 30px;
    background: #578cfd;
    border-radius: 15px;
    color: white;
  }
}
.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px !important;
}
.imgBox {
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 10px;
  }
}
</style>